<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center">
                    <h2 class="font-header text-white text-center mb-7">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 col-lg-8 text-center offset-lg-2 mb-5">
                    <div class="mx-auto text-white">
                       A játékban részt vesznek a Milka táblás termékek, melyeket Magyarországon valamely <b>Auchan áruházban</b> vagy az <b>Auchan Online Áruházában</b><br>( <a class="text-white fw-bold" href="https://auchan.hu/shop" target="_blank">https://auchan.hu/shop</a> ) vásároltak a promóció ideje alatt.<br> A választék áruházanként eltérő lehet.
                    </div>
                </div>
                <div class="col-12 mt-5 d-none d-lg-flex align-items-center justify-content-center flex-wrap">
                    <img src="@/assets/imgs/products1.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products2.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products3.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products4.png" alt="milka" class="img-fluid mx-auto" width="250">
                </div> 
                <div class="col-12 my-5 d-none d-lg-flex align-items-center justify-content-center flex-wrap">
                    <img src="@/assets/imgs/products5.png" alt="milka" class="img-fluid mx-auto" width="300">
                    <img src="@/assets/imgs/products6.png" alt="milka" class="img-fluid mx-auto" width="300">
                    <img src="@/assets/imgs/products7.png" alt="milka" class="img-fluid mx-auto" width="300">
                </div> 
                <div class="col-12 d-flex d-lg-none align-items-center justify-content-start" style="overflow-y:auto;">
                    <img src="@/assets/imgs/products1.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products2.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products3.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products4.png" alt="milka" class="img-fluid mx-auto" width="250">
                    <img src="@/assets/imgs/products5.png" alt="milka" class="img-fluid mx-auto" width="300">
                    <img src="@/assets/imgs/products6.png" alt="milka" class="img-fluid mx-auto" width="300">
                    <img src="@/assets/imgs/products7.png" alt="milka" class="img-fluid mx-auto" width="300">
                </div>
                <div class="col-12 text-center my-5">                    
                    <a class="btn btn-primary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>
                             
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-white fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'termekek',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'termekek'
            })
        }
    }
}
</script>