<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container py-5">
                <!-- CONTENT -->
                <div class="container py-5">
                    <div class="row pb-5">
                        <div class="d-flex justify-content-around align-items-center bg-red py-5 mb-5">
                            <p class="text-white mb-0 fs-18">A nyereményjáték csak az <b>Auchan áruházakban</b> és az <b>Online Áruházban</b> fut!</p>
                            <span style="border: 1px solid #ffffff94;height: 100%;" class="d-none d-lg-flex"></span>
                            <img src="@/assets/imgs/logo.svg" alt="Auchan" width="120" />
                        </div>
                    </div>
                    <div class="row py-5 mt-5">
                        <div class="col-12 col-lg-6">
                            <img src="@/assets/imgs/hero-product.png" class="img-fluid"/>
                            <div class="text-center d-none d-lg-block">
                                <p class="text-primary font-primary fs-24 mb-3 mb-lg-0">A promóció időtartama:</p>
                                <p class="hero__date-wrap fs-28 text-white text-uppercase fw-bold mb-0" >2024. szeptember 5. – október 2. </p>
                                <div class="text-center text-dark mt-4">
                                    <button v-if="$store.state.promoStatus != 'before'"
                                        class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                        @click="setGtm('Játszom', 'jatszom')"
                                    >
                                        Játszom!
                                    </button>
                                    <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                                        Játékszabályzat
                                    </a>   
                                </div>                                      
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <h1 class="text-white text-uppercase">Nyerj!</h1>
                            <img src="@/assets/imgs/hero-winnings.png" class="img-fluid"/>
                            <div class="text-center d-block d-lg-none mt-5">
                                <p class="text-primary font-primary fs-24 mb-3 mb-lg-0">A promóció időtartama:</p>
                                <p class="hero__date-wrap fs-28 text-white text-uppercase fw-bold mb-0" >2024. szeptember 5. – október 2. </p>
                                <div class="text-center text-dark mt-4">
                                    <button v-if="$store.state.promoStatus != 'before'"
                                        class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                        @click="setGtm('Játszom', 'jatszom')"
                                    >
                                        Játszom!
                                    </button>
                                    <a class="btn btn-primary--outline me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                                        Játékszabályzat
                                    </a>   
                                </div>                                      
                            </div>
                        </div>                  
                    </div>  
                </div>  
            </div>      
        </div>
        <div class="bg-primary text-center py-5">
            <p class="hero__info-text py-5 mb-0 text-white">A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <b>2024. november 2-ig</b> meg kell őrizni.  A képek csak illusztrációk.</p>
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'gomb_kattintas'
            })
        }
    }
}
</script>